import React from "react";
import { useSelector } from "react-redux";
import "../../assests/css/SingleImageCompoenent.css";

const SingleImageCompoenent = (props) => {
	const { SitesettingData } = useSelector((item) => item.sitesetting);
	console.log(SitesettingData, "weeeeeeeeeeeeeeeeee");
	console.log(props);
	return (
		// <div>

		//     hererer
		// </div>
		<div
			id="jiff02"
			style={{ backgroundImage: `url("${props.content.image}")` }}
			className="SingleImageCompoenent jiff02 cont"
		>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="marquee-wrapper1">
							<marquee scrollamount="5" direction="right" behavior="alternate">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
							<marquee behavior="alternate" direction="left" scrollamount="5">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
							<marquee direction="right" behavior="alternate" scrollamount="6">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
							<marquee behavior="alternate" direction="left" scrollamount="6">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
							<marquee direction="right" behavior="alternate" scrollamount="9">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
							<marquee behavior="alternate" direction="left" scrollamount="6">
								<ul>
									<li>{SitesettingData?.data?.footer_sentence}</li>
								</ul>
							</marquee>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleImageCompoenent;
