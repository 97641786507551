import React, { useEffect } from "react";
import AOS from "aos";
import Header from "../../Components/Header/Header";
import "../../assests/css/Faqs.css";
import faq_banner from "../../assests/images/Faqs/faq_banner.webp";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { faqsActionApi } from "../../store/action/faqsAction";

const Faqs = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(faqsActionApi());
	}, []);
	const { faqsData } = useSelector((state) => state.faqs);
	let faqDatas = faqsData?.data?.faqs?.[0]?.faqs;
	let earthFaqs = faqsData?.data?.faqs?.[1].faqs;
	let LifeFaqs = faqsData?.data?.faqs?.[2].faqs;
	console.log(earthFaqs, "Lollipop");
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	let faqs = [
		{
			faq_num: "faq1",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq2",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq3",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq4",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq5",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq6",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq7",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq8",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
		{
			faq_num: "faq9",
			faq_heading:
				"Odio aenean sed adipiscing diam donec adipiscing tristique risus. Pellentesque habitant morbi tristique",
			faq_desc:
				"Cursus in hac habitasse platea dictumst quisque sagittis. Pharetra sit amet aliquam id diam maecenas. Viverra tellus in hac habitasse platea. Vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Quam viverra orci sagittis eu. Velit egestas dui id ornare. Neque egestas congue quisque egestas. Tellus in hac habitasse platea dictumst vestibulum. Non consectetur a erat nam. Dolor sit amet consectetur adipiscing.",
		},
	];

	return (
		<div className="faqs">
			<div
				style={{ backgroundImage: `url("${faq_banner}")` }}
				className="faq_banner"
			>
				<Header black={true} />
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h1 className="faqs_heading">FAQs</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="faq_content">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 mt-5 mb-5 text-center">
							<div className="tabs mb-5">
								<button className="btn btn-light bt-actv-tc">
									All Category
								</button>
								<button className="btn btn-light bt-tc">
									Space Sustainibility
								</button>
								<button className="btn btn-light bt-tc">
									Earth Sustainibility
								</button>
								<button className="btn btn-light bt-tc">
									Life below water
								</button>
								<button className="btn btn-light bt-tc">Lorem ipsum</button>
							</div>
						</div>
						<div className="col-lg-12">
							<h1 className="faq_mainheading mb-5">Space Sustainibility</h1>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-lg-12 ">
							{faqDatas?.map((item, index) => {
								return (
									<>
										<Accordion
											className={
												expanded === `${item?.id}`
													? `accordian_all`
													: `accordian_allaccordian_all`
											}
											expanded={expanded === `${item?.id}`}
											onChange={handleChange(`${item?.id}`)}
										>
											<AccordionSummary
												className={
													expanded === `${item?.id}`
														? `accordian_colapse`
														: `accordian_colapse_bgwht`
												}
												// className='accordian_colapse'
												expandIcon={
													expanded === `${item?.id}` ? (
														<RemoveIcon
															className={
																expanded === `${item?.id}`
																	? `text-dark`
																	: `text-dark`
															}
														/>
													) : (
														<AddIcon
															className={
																expanded === `${item?.id}`
																	? `text-white`
																	: `text-dark`
															}
														/>
													)
												}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<span className="m-0 faq_heading">
													{item?.question}
												</span>
											</AccordionSummary>
											<AccordionDetails>
												<div className="px-4 py-2">
													<span className="faq_desc">{item?.answer}</span>
												</div>
											</AccordionDetails>
										</Accordion>
									</>
								);
							})}
						</div>
					</div>

					{/* Earth Sustainibility */}

					<div className="row">
						<div className="col-lg-12">
							{/* <div className="brdr-btm-lght mt-2 mb-5"></div> */}
							<div className="col-lg-12">
								<h1 className="faq_mainheading mb-5">Earth Sustainibility</h1>
							</div>
						</div>
					</div>

					<div className="row mb-5">
						<div className="col-lg-12">
							{earthFaqs?.map((item, index) => {
								return (
									<>
										<Accordion
											className={
												expanded === `${item?.id}`
													? `accordian_all`
													: `accordian_allaccordian_all`
											}
											expanded={expanded === `${item?.id}`}
											onChange={handleChange(`${item?.id}`)}
										>
											<AccordionSummary
												className={
													expanded === `${item?.id}`
														? `accordian_colapse`
														: `accordian_colapse_bgwht`
												}
												// className='accordian_colapse'
												expandIcon={
													expanded === `${item?.id}` ? (
														<RemoveIcon
															className={
																expanded === `${item?.id}`
																	? `text-dark`
																	: `text-dark`
															}
														/>
													) : (
														<AddIcon
															className={
																expanded === `${item?.id}`
																	? `text-white`
																	: `text-dark`
															}
														/>
													)
												}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<span className="m-0 faq_heading">{item.question}</span>
											</AccordionSummary>
											<AccordionDetails>
												<div className="px-4 py-2">
													<span className="faq_desc">{item.answer}</span>
												</div>
											</AccordionDetails>
										</Accordion>
									</>
								);
							})}
						</div>
					</div>

					{/* Life below water */}

					<div className="row">
						<div className="col-lg-12">
							{/* <div className="brdr-btm-lght mt-2 mb-5"></div> */}
							<div className="col-lg-12">
								<h1 className="faq_mainheading mb-5">Life below water</h1>
							</div>
						</div>
					</div>

					<div className="row mb-5">
						<div className="col-lg-12">
							{LifeFaqs?.map((item, index) => {
								return (
									<>
										<Accordion
											className={
												expanded === `${item?.id}`
													? `accordian_all`
													: `accordian_allaccordian_all`
											}
											expanded={expanded === `${item?.id}`}
											onChange={handleChange(`${item?.id}`)}
										>
											<AccordionSummary
												className={
													expanded === `${item?.id}`
														? `accordian_colapse`
														: `accordian_colapse_bgwht`
												}
												// className='accordian_colapse'
												expandIcon={
													expanded === `${item?.id}` ? (
														<RemoveIcon
															className={
																expanded === `${item?.id}`
																	? `text-dark`
																	: `text-dark`
															}
														/>
													) : (
														<AddIcon
															className={
																expanded === `${item?.id}`
																	? `text-white`
																	: `text-dark`
															}
														/>
													)
												}
												aria-controls="panel1bh-content"
												id="panel1bh-header"
											>
												<span className="m-0 faq_heading">
													{item?.question}
												</span>
											</AccordionSummary>
											<AccordionDetails>
												<div className="px-4 py-2">
													<span className="faq_desc">{item?.answer}</span>
												</div>
											</AccordionDetails>
										</Accordion>
									</>
								);
							})}
						</div>
					</div>

					{/* lorem lussun */}

					<div className="row">
						<div className="col-lg-12">
							{/* <div className="brdr-btm-lght mt-2 mb-5"></div> */}

							<div className="col-lg-12">
								<h1 className="faq_mainheading mb-5">Lorem ipsum</h1>
							</div>
						</div>
					</div>

					<div className="row mb-5">
						{faqs.map((item, index) => {
							return (
								<div key={index} className="col-lg-12 ">
									<Accordion
										className={
											expanded === `${item.faq_num}`
												? `accordian_all`
												: `accordian_allaccordian_all`
										}
										expanded={expanded === `${item.faq_num}`}
										onChange={handleChange(`${item.faq_num}`)}
									>
										<AccordionSummary
											className={
												expanded === `${item.faq_num}`
													? `accordian_colapse`
													: `accordian_colapse_bgwht`
											}
											// className='accordian_colapse'
											expandIcon={
												expanded === `${item.faq_num}` ? (
													<RemoveIcon
														className={
															expanded === `${item.faq_num}`
																? `text-dark`
																: `text-dark`
														}
													/>
												) : (
													<AddIcon
														className={
															expanded === `${item.faq_num}`
																? `text-white`
																: `text-dark`
														}
													/>
												)
											}
											aria-controls="panel1bh-content"
											id="panel1bh-header"
										>
											<span className="m-0 faq_heading">
												{item.faq_heading}
											</span>
										</AccordionSummary>
										<AccordionDetails>
											<div className="px-4 py-2">
												<span className="faq_desc">{item.faq_desc}</span>
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Faqs;
