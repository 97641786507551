import React, { useEffect } from "react";
import AOS from "aos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "../../assests/css/CustomIamgeSection.css";
import $ from "jquery";
import { Link as ScrollLink } from "react-scroll";
const CustomIamgeSection = (props) => {
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
		// function do_something() {
		// 	$(".enviroment").addClass("stick");
		// }
		// $(document).on("scroll", function () {
		// 	if ($(this).scrollTop() >= $(".enviroment").position().top) {
		// 		do_something();
		// 	}
		// });
	}, []);

	console.log(props);
	return (
		<div
			style={{ backgroundImage: `url("${props.content.image}")` }}
			className={"CustomIamgeSection jiff01 " + props.content.slug}
		>
			<div className="container-fluid">
				<div className="custom_main_layout">
					<div className="">
						<p data-aos="fade-left" className="font_heading">
							{" "}
							{props.content.heading_one}
						</p>
						<p data-aos="fade-right" className="font_heading">
							{" "}
							{props.content.heading_two}
						</p>
						<div data-aos="fade-left" className="brdr-btm-white"></div>
					</div>
					<div className="row">
						<div className="col-lg-7 ">
							<p data-aos="fade-right" className="font_p">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam.
							</p>
							<p data-aos="fade-left" className="mt-5 font_learn_more">
								Learn More <ArrowRightAltIcon className="ml-3" />
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						{/* <div className="arrow-bottom-wrapper">
							<ScrollLink
								activeClass="active"
								to="jiff02"
								spy={true}
								smooth={true}
							>
								<i className="fa fa-angle-double-down text-white"></i>
							</ScrollLink>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomIamgeSection;
