import React, { useEffect } from "react";
import home_firstvideo from "../../assests/video/intro_space.mp4";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { ScrollTrigger } from "gsap/all";
import { Link as ScrollLink } from "react-scroll";
const SeventComponent = () => {
	let navigate = useNavigate();
	return (
		<div className="SeventhComponent" id="seventhComponent">
			<div className="home_page_second_section pos-rel">
				<div className="first_component">
					<video id="video" className="videoTag" autoPlay loop muted>
						<source src={home_firstvideo} type="video/mp4" />
					</video>

					<div className="container-fluid pr">
						<div className="row ps">
							<div className="col-lg-7  pos-rel">
								<div className="sevent_component_content">
									<p className="heading mb-0 lh-01">We Motivate To make</p>
									<p className="heading mb-2">Clean Enviroment</p>
									<div className="brdr-btm-white mb-4"></div>
									<p className="text">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam.
									</p>
									<p
										onClick={() => {
											navigate("/savenature");
										}}
										className="text-white pointer_cursor mt-4"
									>
										Learn More <ArrowRightAltIcon className=" ml-3" />
									</p>
								</div>
							</div>
							<div className="col-lg-5"></div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="arrow-bottom-wrapper">
				<ScrollLink
					activeClass="active"
					to="videoBgPlayPause"
					spy={true}
					smooth={true}
				>
					<i className="fa fa-angle-double-down text-white"></i>
				</ScrollLink>
			</div> */}
		</div>
	);
};

export default SeventComponent;
