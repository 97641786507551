import React, { useEffect } from "react";
import AOS from "aos";
import powerplant from "../../assests/images/custom_image/powerplant.webp";
import hands from "../../assests/images/custom_image/hands.webp";
import Header from "../../Components/Header/Header";
import bg_img from "../../assests/images/Get_Started/bg_img-two.webp";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import Footer_Four from "../../Components/Footer/Footer_Four";
import Footer from "../../Components/Footer/Footer";
import GetStartedTopSection from "../../Components/GetStartedTopSection/GetStartedTopSection";
import page1_one from "../../assests/images/OddEvenImages/page2_one.webp";
import page1_two from "../../assests/images/OddEvenImages/page2_two.webp";
import page1_three from "../../assests/images/OddEvenImages/page2_three.webp";
import page1_four from "../../assests/images/OddEvenImages/page2_four.png";
import OddEvenImagesContent from "../../Components/OddEvenImagesContent/OddEvenImagesContent";
import "../../assests/css/Recievers.css";
import WhyToGetInvolved from "../../Components/WhyToGetInvolved/WhyToGetInvolved";
import WeWouldLike from "../../Components/WeWouldLikeToInvest/WeWouldLike";
import {
	break1_1,
	break1_2,
	break2_1,
	break2_2,
	break3_1,
	break3_2,
	break4_1,
	break4_2,
} from "../../constant";

const Givers = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let OddEvenContent = [
		{
			image1: break1_1,
			image2: break1_2,
			heading: "We do good for life below the ocean",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
			items_lists: [
				{
					text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
				},
				{
					text: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
				},
				{
					text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
				},
			],
		},
		{
			image1: break2_1,
			image2: break2_2,
			heading: "We do good to eliminate hunger form world",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
			items_lists: [
				{
					text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
				},
				{
					text: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
				},
				{
					text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
				},
			],
		},
		{
			image1: break3_1,
			image2: break3_2,
			heading: "We do Good to make the world Literate",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
			items_lists: [
				{
					text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
				},
				{
					text: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
				},
				{
					text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
				},
			],
		},
		{
			image1: break4_1,
			image2: break4_2,
			heading: "We do good for Affordable and clean energy",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
			items_lists: [
				{
					text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
				},
				{
					text: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
				},
				{
					text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
				},
			],
		},
	];

	let MultiContent_One = {
		image: powerplant,
		heading_one: "We making the change",
		heading_two: "For better Future",
	};
	let MultiContent_Two = {
		image: hands,
		heading_one: "We Motivate to do",
		heading_two: "Social Good",
	};

	let bg_top_sec = {
		bg_image: bg_img,
		heading_one: "Be a Part of",
		heading_two: "Benefacto team",
		heading_three: "To do Good",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
	};

	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	return (
		<div className="ReciveresPage">
			<Header black={true} />

			<section className="top_sec_page">
				<GetStartedTopSection content={bg_top_sec} />
			</section>
			<section className="oddevensection">
				<OddEvenImagesContent content={OddEvenContent} />
			</section>
			<section className="oddevensection">
				<div className="container"></div>
				<WhyToGetInvolved />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_Two} />
			</section>
			<section className="wewouldlike">
				<WeWouldLike />
			</section>
			<section className="Footer_Four_section">
				<Footer_Four />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default Givers;
