import React, { useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ContactApi } from "../../store/action/contactAction";
import { useDispatch } from "react-redux";

const WeWouldLike = () => {
	let [state, setState] = useState({
		fname: "",
		lname: "",
		email: "",
		number: "",
		message: "",
	});
	const dispatch = useDispatch();
	const setChange = (e) => {
		setState((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};
	const contactSubmit = () => {
		let formData = new FormData();
		formData.append("first_name", state.fname);
		formData.append("last_name", state.lname);
		formData.append("email", state.email);
		formData.append("phone", state.number);
		formData.append("description", state.message);
		dispatch(ContactApi(formData));
		setState({
			fname: "",
			lname: "",
			email: "",
			number: "",
			message: "",
		});
	};
	return (
		<div className="WeWouldLike">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-4 align-center">
						<p data-aos="fade-left" className="heading">
							We Would <br /> like You <br /> to <br /> Invest{" "}
						</p>
					</div>
					<div className="col-lg-4">
						<p data-aos="fade-right" className="label">
							First Name
						</p>
						<input
							data-aos="fade-left"
							className="inpt-fld"
							name="fname"
							value={state.fname}
							onChange={setChange}
						/>
						<p data-aos="fade-right" className="label">
							Last Name
						</p>
						<input
							data-aos="fade-left"
							className="inpt-fld"
							name="lname"
							value={state.lname}
							onChange={setChange}
						/>
						<p data-aos="fade-right" className="label">
							Email
						</p>
						<input
							data-aos="fade-left"
							className="inpt-fld"
							name="email"
							value={state.email}
							onChange={setChange}
						/>
						<p data-aos="fade-right" className="label">
							Phone Number
						</p>
						<input
							data-aos="fade-left"
							className="inpt-fld"
							name="number"
							value={state.number}
							onChange={setChange}
						/>
					</div>
					<div className="col-lg-4 pos-rel">
						<textarea
							style={{ position: "absolute", zIndex: "111" }}
							data-aos="fade-right"
							placeholder="Message"
							className="inpt-txtarea"
							name="message"
							value={state.message}
							onChange={setChange}
						></textarea>
					</div>
					<div className="col-lg-12 wewew">
						<button
							className="btn btn-light bt-learn-more"
							onClick={contactSubmit}
						>
							Submit <ArrowRightAltIcon className=" right_icon_learn_more" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WeWouldLike;
