import React, { useEffect } from "react";
import { AOS } from "aos";
import "../../assests/css/GetStartedTopSection.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useSelector } from "react-redux";

const GetStartedTopSection = (props) => {
	const { cummunityData } = useSelector((item) => item.cummunity);
	console.log(cummunityData, "sdasdsaadsads");
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	return (
		<div
			style={{ backgroundImage: `url("${props.content.bg_image}")` }}
			className="GetStartedTopSection mb-getStarted"
		>
			<div className="container-fluid">
				<div className="row ">
					<div className="col-lg-7">
						<h1 data-aos="fade-left" className="heading">
							{props.content?.heading_one}
						</h1>
						<h1 data-aos="fade-left" className="heading">
							{props.content?.heading_two}
						</h1>
						<h1 data-aos="fade-left" className="heading">
							{props.content?.heading_three}
						</h1>
						<p data-aos="fade-right" className="text my-4">
							{props.content.text}
						</p>
						<button
							data-aos="fade-left"
							className="btn btn-light bt-get-started"
						>
							Get Started <ArrowRightAltIcon className="ml-2" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GetStartedTopSection;
