import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import FirstComponent from "../../Components/Home/FirstComponent";
import SecondComponent from "../../Components/Home/SecondComponent";
import ThirdComponent from "../../Components/Home/ThirdComponent";
import FourthComponent from "../../Components/Home/FourthComponent";
import ReactPageScroller from "react-page-scroller";
import "../../assests/css/Home.css";
import "../../assests/css/Common.css";
import Header from "../../Components/Header/Header";
import FifthComponent from "../../Components/Home/FifthComponent";
import SixthComponent from "../../Components/Home/SixthComponent";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import CustomIamgeSection2 from "../../Components/CustomIamgeSection/CustomIamgeSection";
import hands from "../../assests/images/custom_image/hands.webp";
import Footer_Four from "../../Components/Footer/Footer_Four";
import Footer from "../../Components/Footer/Footer";
import SingleImageCompoenent from "../../Components/SingleImage/SingleImageCompoenent";
import Single_image_one from "../../assests/images/single_image/bg-fish.png";
import { marquee, marquee2 } from "../../constant/index";
import TwelfthComponent from "../../Components/Home/TwelfthComponent";
import TenthComponent from "../../Components/Home/TenthComponent";
import bg_space_video from "../../assests/video/intro_space.mp4";
import bg_green_video from "../../assests/video/intro_video.mp4";
import CustomVideoSection from "../../Components/CustomVideoSection/CustomVideoSection";
import SeventComponent from "../../Components/Home/SeventComponent";
import VideoBgPausePlay from "../../Components/VideoBgPausePlay/VideoBgPausePlay";
import help_enviroment from "../../assests/images/home/tenth_component.png";
import OurServices from "../../Components/Home/OurServices";
import OurApproach from "../../Components/Home/OurApproach";
import anime from "animejs";
import LifeBelowWater from "../LifeBelowWater/LifeBelowWater";
import Blog_section from "../Blogs/Blog_section";
import Motivate from "../../Components/SingleImage/motivate";
import Recievers from "../Recievers/Recievers";
import Community from "../Community/Community";
import { gsap } from "gsap/all";
import $ from "jquery";
import { ScrollTrigger } from "gsap/all";

const Home = () => {
	useEffect(() => {
		var ml4 = {};
		ml4.opacityIn = [0, 1];
		ml4.scaleIn = [0.2, 1];
		ml4.scaleOut = 3;
		ml4.durationIn = 800;
		ml4.durationOut = 600;
		ml4.delay = 500;

		anime
			.timeline({ loop: true })
			.add({
				targets: ".ml4 .letters-1",
				opacity: ml4.opacityIn,
				scale: ml4.scaleIn,
				duration: ml4.durationIn,
			})
			.add({
				targets: ".ml4 .letters-1",
				opacity: 0,
				scale: ml4.scaleOut,
				duration: ml4.durationOut,
				easing: "easeInExpo",
				delay: ml4.delay,
			})
			.add({
				targets: ".ml4 .letters-2",
				opacity: ml4.opacityIn,
				scale: ml4.scaleIn,
				duration: ml4.durationIn,
			})
			.add({
				targets: ".ml4 .letters-2",
				opacity: 0,
				scale: ml4.scaleOut,
				duration: ml4.durationOut,
				easing: "easeInExpo",
				delay: ml4.delay,
			})
			.add({
				targets: ".ml4 .letters-3",
				opacity: ml4.opacityIn,
				scale: ml4.scaleIn,
				duration: ml4.durationIn,
			})
			.add({
				targets: ".ml4 .letters-3",
				opacity: 0,
				scale: ml4.scaleOut,
				duration: ml4.durationOut,
				easing: "easeInExpo",
				delay: ml4.delay,
			})
			.add({
				targets: ".ml4",
				opacity: 0,
				duration: 500,
				delay: 500,
			})
			.add({
				targets: ".ml4 .letters-4",
				opacity: 0,
				scale: ml4.scaleOut,
				duration: ml4.durationOut,
				easing: "easeInExpo",
				delay: ml4.delay,
			})
			.add({
				targets: ".ml4 .letters-4",
				opacity: ml4.opacityIn,
				scale: ml4.scaleIn,
				duration: ml4.durationIn,
			});
		window.scrollTo(0, 0);
		// // SLIDE SECTION STARTS HERE

		// // SLIDE SECTION ENDS HERE
	}, []);
	const [currentPage, setState] = useState(null);

	const handlePageChange = (number) => {
		setState(number);
	};
	const handleBeforePageChange = (number) => {
		// console.log(number);
	};
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	let MultiContent_One = {
		image: hands,
		heading_one: "We Motivate to do",
		heading_two: "Social Good",
	};

	let singleImageConenet = {
		image: marquee,
	};

	let motivatecontent = {
		image: marquee2,
	};
	let bgVideo_Space = {
		video: bg_space_video,
	};

	let bgVideo_Forest = {
		video: bg_green_video,
	};

	let we_help_to_save_enviroment = {
		id: 2,
		slug: "enviroment",
		image: help_enviroment,
		heading_one: "We help to",
		heading_two: "Save Enviroment",
	};

	let we_motivate_to_do_good = {
		id: 1,
		slug: "Motivate",
		image: hands,
		// heading_one: "We Motivate to do",
		// heading_two: "Social Good",
		heading_one: "We help to",
		heading_two: "Save Enviroment",
	};

	return (
		<div className="home_page">
			<Header />
			{/* <ReactPageScroller
                pageOnChange={handlePageChange}
                onBeforePageScroll={handleBeforePageChange}
                customPageNumber={currentPage}> */}

			{/* We care to do good starts */}
			<div className="sec">
				<FirstComponent />

				{/* We care to do good ends */}

				{/* We aspire to do good starts */}
				{/* <SecondComponent /> */}
				{/* We aspire to do good ends */}

				{/* We inspire to do good starts */}
				{/* <ThirdComponent /> */}
				{/* We inspire to do good ends */}

				{/* We Motivate to do good starts */}
				{/* <FourthComponent /> */}
				{/* We Motivate to do good ends */}

				{/* forest video section starts here */}
				{/* <CustomVideoSection content={bgVideo_Forest} /> */}
				{/* forest video section ends here */}

				{/* space video section starts here */}
				{/* <CustomVideoSection content={bgVideo_Space} /> */}
				{/* space video section ends here */}

				{/* We Motivate to make clean enviroment starts */}

				<SeventComponent />

				{/* We Motivate to make clean enviroment ends */}

				{/* We are trying to create better future starts */}

				<VideoBgPausePlay />
			</div>
			{/* We are trying to create better future ends */}

			{/* Our Approach Article starts starts */}
			<OurApproach />
			{/* Our Approach starts ends */}

			{/* Recievers Article starts starts */}
			<Recievers />
			{/* Recievers starts ends */}
			{/* Community Article starts starts */}
			<Community />
			{/* Community starts ends */}
			<div className="cont">
				{/* We help to save enviroment starts */}
				<CustomIamgeSection content={we_help_to_save_enviroment} />
				{/* We help to save enviroment ends */}

				{/* we motivate we care we aspire starts */}
				<SingleImageCompoenent content={singleImageConenet} />
				{/* we motivate  we care we aspire ends */}
				{/* Protect Eco System starts */}
				<TwelfthComponent />
				{/* Protect Eco System ends */}
			</div>
			{/* Our Service starts */}
			<OurServices />
			{/* Our Service starts ends */}
			{/* blog section starts here */}
			<Blog_section />
			{/* blog section starts here */}
			{/* motivate section starts here */}
			<div className="sin">
				{/* <Motivate content={motivatecontent} /> */}
				<div className="pu">
					<SingleImageCompoenent content={singleImageConenet} />
				</div>

				{/* motivate section starts here */}
				{/* we Motivate image only starts */}
				<div className="pu">
					<CustomIamgeSection content={we_motivate_to_do_good} />
				</div>

				{/* we Motivate image only ends */}
			</div>
			{/* four sec footer starts */}
			<Footer_Four />
			{/* four sec footer ends */}

			{/* main footer starts */}
			<Footer />
			{/* main footer ends */}

			{/* </ReactPageScroller> */}
		</div>
	);
};

export default Home;
