import React from "react";
import { useSelector } from "react-redux";

const TermsOfUse = () => {
	const { policyApiss } = useSelector((item) => item.policy);
	return (
		<div className="tc_content">
			<p
				className="blog-des"
				dangerouslySetInnerHTML={{
					// __html: policyApiss.data.data[2].content,
					__html: policyApiss == "" ? "" : policyApiss.data.data[2].content,
				}}
			/>
		</div>
	);
};

export default TermsOfUse;
