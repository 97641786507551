import React from "react";
import img1 from "../../assests/images/About_us/img1.png";
import img2 from "../../assests/images/About_us/img2.png";
import img3 from "../../assests/images/About_us/img3.png";
import img4 from "../../assests/images/About_us/img4.png";
import service_img1 from "../../assests/images/About_us/loop1.webp";
import service_img2 from "../../assests/images/About_us/loop2.webp";
import service_img3 from "../../assests/images/About_us/loop3.webp";
import service_img4 from "../../assests/images/About_us/loop4.png";
import { useSelector } from "react-redux";

const WhatWeAreAbout = () => {
	const { aboutData } = useSelector((state) => state.Home);
	let abouUs_Services = [
		{
			heading: "We do For Student to get higher education",
			image: service_img1,
		},
		{
			heading: "We  Eliminate hunger To spread Happiness",
			image: service_img2,
		},
		{
			heading: "We do Good to make the world cleaner",
			image: service_img3,
		},
		{
			heading: "We do Good to   Clean Water and Sanitation",
			image: service_img4,
		},
	];

	let arr_Image = [
		{
			image: img1,
			name: "Byron Hopkins",
		},
		{
			image: img2,
			name: "Nathan Banks",
		},
		{
			image: img3,
			name: "Blanche Moody",
		},
		{
			image: img4,
			name: "Ora Vargas",
		},
	];

	return (
		<div className="WhatWeAreAbout">
			<div className="container-fluid">
				<div className="row mb-our_impact">
					<div className="col-lg-2">
						<p data-aos="fade-left" className="ourtxt_head">
							Our impact{" "}
						</p>
						<p data-aos="fade-right" className="ourtxt_txt">
							Id aliquet lectus proin nibh nisl conum. Sagittis id conctetur
							purus ut faucibus pulvinar elementum. Lorem ipsum dolor sit amet,
							consectetur ex ea commodo consequat.
						</p>
					</div>
					<div className="col-lg-10">
						<div className="row">
							{aboutData?.data?.impact?.data?.map((item, index) => {
								return (
									<div className="col-md-6" key={index}>
										<div data-aos="flip-down" className="gry_simpleBox mt-0">
											<>
												<p className="gry_simpleBox_head">{item?.title}</p>
												<p className="gry_simpleBox_txt">{item?.description}</p>
											</>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					{/* <div className="col-lg-5">
						<div data-aos="flip-down" className="gry_simpleBox mt-0">
							<p className="gry_simpleBox_head">
								Egestas quis ipsum suspendisseultrices gravida
							</p>
							<p className="gry_simpleBox_txt">
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu. Libero nunc consequat
								interdum varius sit amet mattis. Egestas quis ipsum Suspendisse.
							</p>
						</div>
						<div data-aos="flip-down" className="gry_simpleBox">
							<p className="gry_simpleBox_head">
								Egestas quis ipsum suspendisseultrices gravida
							</p>
							<p className="gry_simpleBox_txt">
								Luctus accumsan tortor posuere ac ut consequat semper viverra
								nam. Ac auctor augue mauris augue neque. Amet commodo nulla
								facilisi nullam vehicula ipsum a arcu. Libero nunc consequat
								interdum varius sit amet mattis. Egestas quis ipsum Suspendisse.
							</p>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default WhatWeAreAbout;
