import React from "react";
import "../../assests/css/Footer.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
	const navigate = useNavigate();
	const { SitesettingData } = useSelector((item) => item.sitesetting);
	console.log(SitesettingData, "weeeeeeeeeeeeeeeeee");
	const HandleClick = (path) => {
		window.open(path, "_blank");
	};
	return (
		<footer className="footer">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6">
						<div className="text_area">
							<p className="sign_up_stay">
								Sign Up to Stay upto date on global innovation
							</p>
							<button className="btn btn-light bt-learn-more">
								Learn More{" "}
								<ArrowRightAltIcon className=" right_icon_learn_more" />
							</button>
						</div>
					</div>
					<div className="col-lg-3">
						<p className="footer_pages_text">
							<Link to="/blog-categoriesOur"> Blogs</Link>
						</p>
						<p
							className="footer_pages_text"
							style={{ cursor: "pointer" }}
							onClick={() => HandleClick(`${SitesettingData?.data?.linkedin}`)}
						>
							Linkedin
						</p>
						<p
							className="footer_pages_text"
							style={{ cursor: "pointer" }}
							onClick={() => HandleClick(`${SitesettingData?.data?.facebook}`)}
						>
							Facebook
						</p>
						<p
							className="footer_pages_text"
							style={{ cursor: "pointer" }}
							onClick={() => HandleClick(`${SitesettingData?.data?.instagram}`)}
						>
							Instagram
						</p>
						<p
							className="footer_pages_text"
							style={{ cursor: "pointer" }}
							onClick={() => HandleClick(`${SitesettingData?.data?.twitter}`)}
						>
							Twitter
						</p>
						<p className="footer_pages_text">
							{" "}
							<Link to="/space-sustainibility">Space Sustainibility</Link>
						</p>

						<div className="light_text_div">
							<p className="footer_pages_down_text mb-4">
								<Link to="/terms">Legal Links</Link>
							</p>
							<p className="footer_pages_down_text mt-4">
								{SitesettingData?.data?.copyright}
							</p>
						</div>
					</div>
					<div className="col-lg-3">
						<p className="footer_pages_text">
							<Link to="/savenature">Save Nature</Link>
						</p>
						<p className="footer_pages_text">
							{" "}
							<Link to="/savespace">Save Space</Link>
						</p>
						<p className="footer_pages_text">
							{" "}
							<Link to="/terms">Terms</Link>
						</p>
						<p className="footer_pages_text">
							{" "}
							<Link to="/howstarted">How Started</Link>
						</p>
						<p className="footer_pages_text">
							{" "}
							<Link to="/cryptotoken">cryptotoken</Link>
						</p>
						<p className="footer_pages_text">
							<Link to="/recievers-content"> Recievers Content</Link>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
