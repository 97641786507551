import React, { useEffect } from "react";
import powerplant from "../../assests/images/custom_image/Community.webp";
import hands from "../../assests/images/custom_image/hands.webp";
import Header from "../../Components/Header/Header";
import bg_img from "../../assests/images/custom_image/Comm_top.webp";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import Footer_Four from "../../Components/Footer/Footer_Four";
import Footer from "../../Components/Footer/Footer";
import GetStartedTopSection from "../../Components/GetStartedTopSection/GetStartedTopSection";
import page1_one from "../../assests/images/OddEvenImages/page2_one.webp";
import page1_two from "../../assests/images/OddEvenImages/page2_two.webp";
import page1_three from "../../assests/images/OddEvenImages/page2_three.webp";
import page1_four from "../../assests/images/OddEvenImages/page2_four.webp";
import OddEvenImagesContent from "../../Components/OddEvenImagesContent/OddEvenImagesContent";
import "../../assests/css/Recievers.css";
import WhyToGetInvolved from "../../Components/WhyToGetInvolved/WhyToGetInvolved";
import WeWouldLike from "../../Components/WeWouldLikeToInvest/WeWouldLike";
import OurCommunity_Person from "../../Components/OurCommunity_Person/OurCommunity_Person";
import We_Empower from "../../Components/Community_We_Empower/We_Empower";
import { AOS } from "aos";
import { useDispatch } from "react-redux";
import { CummunityApi } from "../../store/action/CummunityAction";

const CommunityPage = () => {
	let dispatch = useDispatch();
	useEffect(() => {
		dispatch(CummunityApi());
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let MultiContent_One = {
		image: powerplant,
		heading_one: "The best place for you",
		heading_two: "To invest for Good",
	};
	let MultiContent_Two = {
		image: hands,
		heading_one: "We Motivate to do",
		heading_two: "Social Good",
	};

	let bg_top_sec = {
		bg_image: bg_img,
		heading_one: "Be a Part of",
		heading_two: "Benefacto team",
		heading_three: "To do Good",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
	};

	return (
		<div className=" CommunityPage">
			<Header black={true} />
			<section className="top_sec_page">
				<GetStartedTopSection content={bg_top_sec} />
			</section>
			<section className="We_Empower_section">
				<We_Empower />
			</section>
			<section className="OurCommunity_Person_sec mb-community no-mar">
				<OurCommunity_Person />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_Two} />
			</section>
			<section className="wewouldlike">
				<WeWouldLike />
			</section>
			<section className="Footer_Four_section">
				<Footer_Four />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default CommunityPage;
