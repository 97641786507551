import React from "react";
import "../../assests/css/Help_To_Save.css";

const HelpToSave = () => {
	return (
		<div className="help_to_save">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-2"></div>
					<div className="col-lg-8 text-center">
						<p className="heading_help_save">We help to Save Nature</p>
						<div className="border_btm_heading "></div>
						<p className="heading_help_save_text mt-4 ">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.
						</p>
					</div>
					<div className="col-lg-2"></div>
				</div>
			</div>
		</div>
	);
};

export default HelpToSave;
