import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import SeventComponent from "../../Components/Home/SeventComponent";
import AboutUs from "../AboutUs/AboutUs";
import BlogCategories from "../Blogs/BlogCategories";
import Community from "../Community/Community";
import CommunityPage from "../CommunityPage/CommunityPage";
import CryptoToken from "../CryptoToken/CryptoToken";
import Faqs from "../Faqs/Faqs";
import Givers from "../Givers/Givers";
import Home from "../Home/Home";
import HomeDuplicate from "../HomeDuplicate/HomeDuplicate";
import HowStarted from "../HowStarted/HowStarted";
import Investors from "../Investors/Investors";
import LegalLink from "../LegalLinks/LegalLinks";
import LifeBelowWater from "../LifeBelowWater/LifeBelowWater";
import Partnership from "../Partnership/Partnership";
import Reciveres from "../Recievers/Recievers";
import ReciveresPage from "../ReciveresPage/ReciveresPage";
import SaveNature from "../SaveNature/SaveNature";
import SaveSpace from "../SaveNature/SaveSpace";
import SpaceSustainibility from "../SpaceSustainibility/SpaceSustainibility";
import TermsAndCondition from "../Terms&Condition/TermsAndCondition";

const AllRoutes = () => {
	return (
		<div className="AllRoutes">
			<HashRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/savenature" element={<SaveNature />} />
					<Route path="/savespace" element={<SaveSpace />} />
					<Route path="/terms" element={<TermsAndCondition />} />
					<Route path="/faqs" element={<Faqs />} />
					<Route path="/howstarted" element={<HowStarted />} />
					<Route path="/lifebelowwater" element={<LifeBelowWater />} />
					<Route path="/community-content" element={<Community />} />
					<Route path="/recievers-content" element={<Reciveres />} />
					<Route path="/recievers" element={<ReciveresPage />} />
					<Route path="/givers" element={<Givers />} />
					<Route path="/partnership" element={<Partnership />} />
					<Route path="/Investors" element={<Investors />} />
					<Route path="/legallink" element={<LegalLink />} />
					<Route path="/community" element={<CommunityPage />} />
					<Route
						path="/space-sustainibility"
						element={<SpaceSustainibility />}
					/>
					<Route path="/blog-categories" element={<BlogCategories />} />

					{/* today  */}
					<Route path="/homeduplicate" element={<HomeDuplicate />} />
					<Route path="/aboutus" element={<AboutUs />} />
					<Route path="/cryptotoken" element={<CryptoToken />} />
					<Route path="*" element={<Home />} />
				</Routes>
			</HashRouter>
		</div>
	);
};

export default AllRoutes;
