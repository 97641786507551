import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useSelector } from "react-redux";
import "../../assests/css/about_sticky.css";

const About_sticky = () => {
	const { aboutData } = useSelector((state) => state.Home);
	const Image = aboutData?.data?.about?.image_url;
	console.log(aboutData);
	return (
		<>
			<section className="about_sticky">
				<div className="container-fluid px-0">
					<div className="row">
						<div className="col-lg-4 px-0">
							<div className="img-wrapper">
								<figure className="mb-0">
									<img
										src={Image + aboutData?.data?.about?.image}
										className="img-fluid"
										alt=""
									/>
								</figure>
							</div>
						</div>
						<div className="col-lg-8 px-0">
							<div className="content-wrapper">
								<h2>{aboutData?.data?.about?.title}</h2>
								<p>{aboutData?.data?.about?.meta_description}</p>
							</div>
							<div className="content-wrapper">
								<h4>What we are about</h4>
								<p
									dangerouslySetInnerHTML={{
										__html: aboutData?.data?.about?.description,
									}}
								/>
							</div>
							<div className="happen">
								<div className="row">
									<div className="col-lg-6">
										<h3>The people that make it happen</h3>
									</div>
									<div className="col-lg-6">
										<div className="card-wrapper">
											{aboutData?.data?.team?.data.map((item, index) => {
												return (
													<div
														data-aos="flip-down"
														key={index}
														className="col-lg-6"
													>
														<img
															className="item_img"
															src={`${item?.image_url}${item?.image}`}
														/>
														<p className="item_name mt-3 mb-4">{item?.title}</p>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							<div className="company-sec">
								<h2>
									We are a company that{" "}
									<span className="d-block">DOES GOOD</span>
								</h2>
								{aboutData?.data?.company?.data.map((item, index) => {
									return (
										<div key={index} className="row mb-5 pb-5">
											{/* <div className="col-lg-3"></div> */}
											<div className="col-lg-6 text-right">
												<p data-aos="fade-down" className="content_heading">
													{item.title}
												</p>
											</div>
											<div className="col-lg-6 text-right">
												<img
													data-aos="flip-up"
													className="w-100"
													src={`${item.image_url}${item.image}`}
													alt="About 1"
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default About_sticky;
