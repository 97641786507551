import React from "react";
import space_one from "../../assests/images/savenature/space1.png";
import space_two from "../../assests/images/savenature/space2.png";
import space_three from "../../assests/images/savenature/space3.png";
import space_four from "../../assests/images/savenature/space4.png";
import { useSelector, useDispatch } from "react-redux";
import { SaveSpaceApi } from "../../store/action/SavespaceAction";
import { useState } from "react";

const SpaceSection = () => {
	const dispatch = useDispatch();
	const [handletabs, SetHandletabs] = useState(1);
	const { SaveSpaceData, SaveSpaceCate } = useSelector((item) => item.space);
	console.log(SaveSpaceData, "dssssssssssssssss");
	let space_content = [
		{
			image: space_one,
			heading: "Every factor in an ecosystem depends on every other factor",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
		{
			image: space_two,
			heading: "We do good to save ecosystem For our future",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
		{
			image: space_three,
			heading: "We Motivate People towards Space Sustainibility",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
		{
			image: space_four,
			heading: "We Motivate People to use outer space for peaceful purposes",
			desc: "Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Ac auctor augue mauris augue neque. Amet commodo nulla facilisi nullam vehicula ipsum a arcu. ",
		},
	];
	const HandleChangeCate = (id) => {
		dispatch(SaveSpaceApi(id));
	};
	return (
		<div className="space_content">
			<div className="container-fluid">
				<div className="row for-flex-and-align mb-5 pb-5">
					<div className="col-lg-3 space-items-wrapper">
						<div className="space-items-wrappers">
							<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
								{SaveSpaceCate?.data?.types?.data?.map((item, index) => {
									return (
										<li class="nav-item">
											<a
												class={
													handletabs == item.id ? "nav-link active" : "nav-link"
												}
												id="pills-home-tab"
												data-toggle="pill"
												href={`#pills-home${index + 1}`}
												role="tab"
												aria-controls={`pills-home${index + 1}`}
												aria-selected="true"
												onClick={() => HandleChangeCate(item?.id)}
											>
												{item.title}
											</a>
										</li>
									);
								})}
								{/* <li class="nav-item">
								<a
									class="nav-link"
									id="pills-profile-tab"
									data-toggle="pill"
									href="#pills-profile"
									role="tab"
									aria-controls="pills-profile"
									aria-selected="false"
								>
									Future
								</a>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="pills-contact-tab"
									data-toggle="pill"
									href="#pills-contact"
									role="tab"
									aria-controls="pills-contact"
									aria-selected="false"
								>
									Sustainibility
								</a>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="pills-contactz-tab"
									data-toggle="pill"
									href="#pills-contactz"
									role="tab"
									aria-controls="pills-contactz"
									aria-selected="false"
								>
									Outer space
								</a>
							</li> */}
							</ul>
						</div>
					</div>
					<div className="col-lg-9 mb-5">
						<div
							onMouseOver={() => SetHandletabs(1)}
							className="row mb-3 mt-cus-space"
						>
							<div className="col-lg-6 pr-5 align-center mb-4">
								<p className="space_content_heading">
									We do good to save ecosystem For our future
								</p>
							</div>
							<div className=" col-lg-6 align-center mb-4">
								<p className="space_content_p">
									Luctus accumsan tortor posuere ac ut consequat semper viverra
									nam. Ac auctor augue mauris augue neque. Amet commodo nulla
									facilisi nullam vehicula ipsum a arcu.{" "}
								</p>
							</div>
							<div className="col-lg-12">
								<img className="w-100" src={space_two} alt="image_space" />
							</div>
						</div>
						<div
							onMouseOver={() => SetHandletabs(2)}
							className="row mb-3 mt-cus-space"
						>
							<div className="col-lg-6 pr-5 align-center mb-4">
								<p className="space_content_heading">
									We Motivate People towards Space Sustainibility
								</p>
							</div>
							<div className=" col-lg-6 align-center mb-4">
								<p className="space_content_p">
									Luctus accumsan tortor posuere ac ut consequat semper viverra
									nam. Ac auctor augue mauris augue neque. Amet commodo nulla
									facilisi nullam vehicula ipsum a arcu.{" "}
								</p>
							</div>
							<div className="col-lg-12">
								<img className="w-100" src={space_three} alt="image_space" />
							</div>
						</div>
						<div
							onMouseOver={() => SetHandletabs(3)}
							className="row mb-3 mt-cus-space"
						>
							<div className="col-lg-6 pr-5 align-center mb-4">
								<p className="space_content_heading">
									We Motivate People to use outer space for peaceful purposes
								</p>
							</div>
							<div className=" col-lg-6 align-center mb-4">
								<p className="space_content_p">
									Luctus accumsan tortor posuere ac ut consequat semper viverra
									nam. Ac auctor augue mauris augue neque. Amet commodo nulla
									facilisi nullam vehicula ipsum a arcu.{" "}
								</p>
							</div>
							<div className="col-lg-12">
								<img className="w-100" src={space_four} alt="image_space" />
							</div>
						</div>
						<div
							onMouseOver={() => SetHandletabs(4)}
							className="row mb-3 mt-cus-space"
						>
							<div className="col-lg-6 pr-5 align-center mb-4">
								<p className="space_content_heading">
									We do good to save ecosystem For our future
								</p>
							</div>
							<div className=" col-lg-6 align-center mb-4">
								<p className="space_content_p">
									Luctus accumsan tortor posuere ac ut consequat semper viverra
									nam. Ac auctor augue mauris augue neque. Amet commodo nulla
									facilisi nullam vehicula ipsum a arcu.{" "}
								</p>
							</div>
							<div className="col-lg-12">
								<img className="w-100" src={space_two} alt="image_space" />
							</div>
						</div>
						<div
							onMouseOver={() => SetHandletabs(1)}
							class="tab-content"
							id="pills-tabContent"
						>
							{/* {SaveSpaceData?.data?.types?.data?.map((item, index) => {
								return (
									<div
										class="tab-pane fade show active"
										id={`pills-home${index + 1}`}
										role="tabpanel"
										aria-labelledby="pills-home-tab"
									>
										<div className="row mb-3 mt-cus-space">
											<div className="col-lg-6 pr-5 align-center mb-4">
												<p className="space_content_heading">{item?.title}</p>
											</div>
											<div className=" col-lg-6 align-center mb-4">
												<p className="space_content_p">{item?.description}</p>
											</div>
											<div className="col-lg-12">
												<img
													className="w-100"
													src={`${item?.image_url}${item?.image}`}
													alt="image_space"
												/>
											</div>
										</div>
									</div>
								);
							})} */}
							<div
								class="tab-pane fade"
								id="pills-profile"
								role="tabpanel"
								aria-labelledby="pills-profile-tab"
							>
								<div className="row mb-3 mt-cus-space">
									<div className="col-lg-6 pr-5 align-center mb-4">
										<p className="space_content_heading">
											We do good to save ecosystem For our future
										</p>
									</div>
									<div className=" col-lg-6 align-center mb-4">
										<p className="space_content_p">
											Luctus accumsan tortor posuere ac ut consequat semper
											viverra nam. Ac auctor augue mauris augue neque. Amet
											commodo nulla facilisi nullam vehicula ipsum a arcu.{" "}
										</p>
									</div>
									<div className="col-lg-12">
										<img className="w-100" src={space_two} alt="image_space" />
									</div>
								</div>
							</div>
							<div
								class="tab-pane fade"
								id="pills-contact"
								role="tabpanel"
								aria-labelledby="pills-contact-tab"
							>
								<div className="row mb-3 mt-cus-space">
									<div className="col-lg-6 pr-5 align-center mb-4">
										<p className="space_content_heading">
											We Motivate People towards Space Sustainibility
										</p>
									</div>
									<div className=" col-lg-6 align-center mb-4">
										<p className="space_content_p">
											Luctus accumsan tortor posuere ac ut consequat semper
											viverra nam. Ac auctor augue mauris augue neque. Amet
											commodo nulla facilisi nullam vehicula ipsum a arcu.{" "}
										</p>
									</div>
									<div className="col-lg-12">
										<img
											className="w-100"
											src={space_three}
											alt="image_space"
										/>
									</div>
								</div>
							</div>
							<div
								class="tab-pane fade"
								id="pills-contactz"
								role="tabpanel"
								aria-labelledby="pills-contactz-tab"
							>
								<div className="row mb-3 mt-cus-space">
									<div className="col-lg-6 pr-5 align-center mb-4">
										<p className="space_content_heading">
											We Motivate People to use outer space for peaceful
											purposes
										</p>
									</div>
									<div className=" col-lg-6 align-center mb-4">
										<p className="space_content_p">
											Luctus accumsan tortor posuere ac ut consequat semper
											viverra nam. Ac auctor augue mauris augue neque. Amet
											commodo nulla facilisi nullam vehicula ipsum a arcu.{" "}
										</p>
									</div>
									<div className="col-lg-12">
										<img className="w-100" src={space_four} alt="image_space" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpaceSection;
