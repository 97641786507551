import { AOS } from "aos";
import React, { useEffect } from "react";
import "../../assests/css/OddEvenImagesContent.css";
import { useSelector } from "react-redux";
import gsap, { Power4 } from "gsap/all";
import { ScrollTrigger } from "gsap/all";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import { Expo } from "gsap";

const OddEvenImagesContent = (props) => {
	useEffect(() => {
		let revealContainers = document.querySelectorAll(".reveal");
		revealContainers.forEach((container) => {
			let image = container.querySelector(".reveal-image");

			// use 2 different selectors?
			let imageleft = container.querySelector(".reveal-image-left");

			let imageright = container.querySelector(".reveal-image-right");

			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					toggleActions: "restart none none reset",
				},
			});

			tl.set(container, { autoAlpha: 1 });
			tl.from(container, 1, {
				xPercent: -100,
				ease: Power4.out,
			});
			tl.from(image, 1, {
				xPercent: 100,
				delay: -1,
				duration: 0.5,
				ease: Power4.out,
			});
		});

		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	const { giverData } = useSelector((state) => state.givers);
	return (
		<div className="OddEvenImagesContent">
			<div className="container">
				{giverData?.data?.givers?.data?.map((item, index) => {
					console.log(item, "zzzzzzzzzzzzzzzzzzz");
					if (index % 2 != 0) {
						return (
							<div className="row my-res-odd-even">
								<div className="col-lg-6">
									<div className="img_box">
										<figure>
											<img
												src={`${item.image_url}${item.image}`}
												alt="recevers content"
												className="w-100"
											/>
										</figure>
										<figure>
											<img
												src={`${item.image_url}${item.image2}`}
												alt="recevers content"
												className="w-100 IO"
											/>
										</figure>
									</div>
								</div>
								<div className="col-lg-6 pl-5">
									<p className="heading mb-4">{item.title}</p>
									<p
										className="text"
										dangerouslySetInnerHTML={{
											__html: item?.description,
										}}
									/>
									{item?.items_lists?.map((list_item, i) => {
										return (
											<div
												className="listing_main_div"
												data-aos="flip-down"
												key={i}
											>
												<p className="number">0{i + 1}</p>
												<p className="text">{list_item.text}</p>
											</div>
										);
									})}
								</div>
							</div>
						);
					} else {
						console.log(item, "pppppppppppppppppp");
						return (
							<div className="row my-res-odd-even">
								<div className="col-lg-6 pr-5">
									<p className="heading mb-4">{item.title}</p>
									<p
										className="text"
										dangerouslySetInnerHTML={{
											__html: item?.description,
										}}
									/>
									{item?.items_lists?.map((list_item, i) => {
										return (
											<div
												className="listing_main_div"
												data-aos="flip-up"
												key={i}
											>
												<p className="number">0{i + 1}</p>
												<p className="text">{list_item.text}</p>
											</div>
										);
									})}
								</div>
								<div className="col-lg-6">
									<div className="img_box">
										<figure>
											<img
												data-aos="fade-right"
												src={`${item.image_url}${item.image}`}
												alt="recevers content"
												className="w-100"
											/>
										</figure>
										<figure>
											<img
												data-aos="fade-right"
												data-aos-offset="500"
												data-aos-easing="ease-in-sine"
												src={`${item.image_url}${item.image2}`}
												alt="recevers content"
												className="w-100"
											/>
										</figure>
									</div>
								</div>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

export default OddEvenImagesContent;
