import React, { useEffect } from "react";
import { AOS } from "aos";
import img1 from "../../assests/images/Community/arr1.webp";
import img2 from "../../assests/images/Community/arr2.webp";
import img3 from "../../assests/images/Community/arr3.webp";
import img4 from "../../assests/images/Community/arr4.webp";
import img5 from "../../assests/images/Community/arr5.webp";
import img6 from "../../assests/images/Community/arr6.webp";
import { useSelector } from "react-redux";

const We_Empower = () => {
	const { cummunityData } = useSelector((item) => item.cummunity);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
	}, []);
	let arr = [
		{
			image_one: img1,
			image_two: img2,
			image_heading: "Lorem Ipsum",
			image_desc:
				"Egestas integer eget aliquet nibh praesent tristique magna sit .",
			heading: "Lorem Ipsum Dolor cillum dolore Eu fugiat nulla pariatur",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. ",
		},
		{
			image_one: img3,
			image_two: img4,
			image_heading: "Lorem Ipsum",
			image_desc:
				"Egestas integer eget aliquet nibh praesent tristique magna sit .",
			heading: "Lorem Ipsum Dolor cillum dolore Eu fugiat nulla pariatur",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. ",
		},
		{
			image_one: img5,
			image_two: img6,
			image_heading: "Lorem Ipsum",
			image_desc:
				"Egestas integer eget aliquet nibh praesent tristique magna sit .",
			heading: "Lorem Ipsum Dolor cillum dolore Eu fugiat nulla pariatur",
			desc: "Egestas integer eget aliquet nibh praesent tristique magna sit amet. Leo vel fringilla est ullamcorper eget nulla facilisi. Dui ut ornare lectus sit amet est placerat in. Tellus at urna condimentum mattis. Non pulvinar neque laoreet suspendisse interdum consectetur. Ut tristique et egestas quis ipsum suspendisse ultrices. ",
		},
	];

	return (
		<div className="We_Empower">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12 text-center">
						<p data-aos="flip-up" className="we_empower_heading">
							We Empower People to bring about <br />
							social Good And Positive Impact
						</p>
					</div>
					{cummunityData?.data?.community?.data?.map((item, index) => {
						return (
							<div key={index} className="row mb_we_power">
								<div className="col-lg-6 align-center">
									<p data-aos="fade-right" className="heading">
										{item?.title}
									</p>
									<p data-aos="fade-left" className="txt">
										{item?.description}
									</p>
								</div>
								<div className="col-lg-6">
									<div className="row">
										<div
											className="col-lg-6 mt-img"
											//  data-aos="zoom-in-up"
										>
											<img
												data-aos="flip-up"
												className="w-100"
												src={`${item?.image_url}${item?.image1}`}
											/>
											<p data-aos="fade-left" className="heading_image mt-3">
												{item?.image_title1}
											</p>
											<p data-aos="fade-right" className="txt">
												{item?.image_description1}
											</p>
										</div>
										<div
											className="col-lg-6"
											// data-aos="zoom-in-up"
										>
											<img
												data-aos="flip-down"
												className="w-100"
												src={`${item?.image_url}${item?.image2}`}
											/>
											<p data-aos="fade-right" className="heading_image mt-3">
												{item?.image_title2}
											</p>
											<p data-aos="fade-left" className="txt">
												{item?.image_description2}
											</p>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default We_Empower;
