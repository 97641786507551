import React, { useEffect } from "react";
import AOS from "aos";
import "../../assests/css/SaveNature.css";
import Video_First_Section from "../../Components/Nature Page/Video_First_Section";
import HelpToSave from "../../Components/Nature Page/HelpToSave";
import Cards_Section from "../../Components/Nature Page/Cards_Section";
import Footer from "../../Components/Footer/Footer";
import Footer_Four from "../../Components/Footer/Footer_Four";
import CustomIamgeSection from "../../Components/CustomIamgeSection/CustomIamgeSection";
import powerplant from "../../assests/images/custom_image/powerplant.webp";
import hands from "../../assests/images/custom_image/hands.webp";
import home_firstvideo from "../../assests/video/intro_video.mp4";
import bg_video_img from "../../assests/images/home/left_home.png";
import { SaveNatureApi } from "../../store/action/SaveNatureAction";
import { useDispatch, useSelector } from "react-redux";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const SaveNature = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(SaveNatureApi());
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setTimeout(() => {
			AOS.init();
		}, 1000);
		let proxy = { skew: 0 },
			skewSetter = gsap.quickSetter(".pos-rel", "skewY", "deg"), // fast
			clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

		ScrollTrigger.create({
			onUpdate: (self) => {
				let skew = clamp(self.getVelocity() / -300);
				// only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
				if (Math.abs(skew) > Math.abs(proxy.skew)) {
					proxy.skew = skew;
					gsap.to(proxy, {
						skew: 0,
						duration: 0.8,
						ease: "power3",
						overwrite: true,
						onUpdate: () => skewSetter(proxy.skew),
					});
				}
			},
		});

		// make the right edge "stick" to the scroll bar. force3D: true improves performance
		gsap.set(".pos-rel", { transformOrigin: "right center", force3D: true });
	}, []);

	let MultiContent_One = {
		image: powerplant,
		heading_one: "We making the change",
		heading_two: "For better Future",
	};
	let MultiContent_Two = {
		image: hands,
		heading_one: "We Motivate to do",
		heading_two: "Social Good",
	};

	let video_sec_content = {
		video: home_firstvideo,
		heading_one: "We do good for",
		heading_two: "Earth Sustainibility",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
		bg_imgleft: bg_video_img,
	};

	return (
		<div className="nature_page_one">
			<section className="first_section">
				<Video_First_Section content={video_sec_content} />
			</section>
			<section className="second_section">
				<HelpToSave />
			</section>
			<section className="third_section">
				<Cards_Section />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_One} />
			</section>
			<section className="custom_images_section">
				<CustomIamgeSection content={MultiContent_Two} />
			</section>
			<section className="Footer_Four_section">
				<Footer_Four />
			</section>
			<section className="last_section">
				<Footer />
			</section>
		</div>
	);
};

export default SaveNature;
