import React, { useEffect, useState } from "react";
import AOS from "aos";
import Header from "../../Components/Header/Header";
import "../../assests/css/Community&Reciveres.css";
import first_img from "../../assests/images/Review_Community/first_img.webp";
import second_img from "../../assests/images/Review_Community/second_img.webp";
import $ from "jquery";
import gsap from "gsap";
import { useSelector } from "react-redux";

const Community = () => {
	const [trans, settrans] = useState(200);
	const [trans2, settrans2] = useState(0);
	const [sliderimg, setsliderimg] = useState(second_img);
	const [sliderimg2, setsliderimg2] = useState(first_img);
	const { homeData } = useSelector((state) => state.Home);
	let Image = homeData?.data?.community?.image_url;

	useEffect(() => {
		// Slide_img_1 starts here
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".pi",
				start: "top bottom",
				end: "bottom top",
				scrub: true,
				// markers: true,
			},
		});
		tl.to(".so-1", { yPercent: -30, duration: 1 });
		// Slide_img_1 end here
		// Slide_img_2 starts here
		const tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: ".pi",
				start: "top bottom",
				end: "bottom top",
				scrub: true,
				// markers: true,
			},
		});
		tl2.to(".so-2", { yPercent: 35, duration: 1 });
		// Slide_img_2 end here
		$(window).scroll(function () {
			var scroll = $(document).scrollTop();
			console.log(scroll);
			if (scroll > 7000 && scroll < 7800) {
				// let maxScroll = 7800;
				let maxScroll = window.innerHeight * 100;
				let newS = maxScroll - scroll;
				let percentage = (newS / maxScroll) * 100;
				let newTranslate = 100 * (percentage / 100);
				settrans(newTranslate);
				settrans2(newTranslate);
				setsliderimg(first_img);
				setsliderimg2(second_img);
			} else {
				settrans(0);
				setsliderimg(second_img);
				settrans2(150);
				setsliderimg2(first_img);
			}

			if (scroll > 7500) {
				$(".pi").addClass("darkHeader");
				console.log("Approch" + scroll);
			} else {
				$(".pi").removeClass("darkHeader");
			}
		});
		$(window).on("load", function () {
			$(".slider-img-wrapper img").addClass("fadein");
		});
	}, []);
	return (
		<div className="Community pi">
			{/* <Header black={true} /> */}
			<div className="Community_content">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-6 pr-5">
							<p className="heading mb-0">Community</p>
							<div className="brdr-btm-line mb-3"></div>
							{/* <p className="text">
								Sodales ut etiam sit amet. Lacus vestibulum sed arcu non odio
								euismod lacinia at. Porttitor massa id neque aliquam vestibulum
								morbi blandit cursus. Pellentesque elit ullamcorper dignissim
								cras tincidunt lobortis feugiat vivamus at. Ipsum dolor sit amet
								consectetur adipiscing elit ut aliquam purus.
							</p>
							<p className="text mt-4">
								Cursus in hac habitasse platea dictumst quisque sagittis.
								Pharetra sit amet aliquam id diam maecenas. Viverra tellus in
								hac habitasse platea. Vulputate enim nulla aliquet porttitor
								lacus luctus accumsan tortor posuere. Quam viverra orci sagittis
								eu. Velit egestas dui id ornare. Neque egestas congue quisque
								egestas. Tellus in hac habitasse platea dictumst vestibulum. Non
								consectetur a erat nam. Dolor sit amet consectetur adipiscing.
							</p>
							<p className="text mt-4">
								Non consectetur a erat nam. Dolor sit amet consectetur
								adipiscing.
							</p> */}
							<p
								className="blog-des"
								dangerouslySetInnerHTML={{
									__html: homeData?.data?.community?.description,
								}}
							/>
						</div>
						<div className="col-lg-6">
							<div className="slider-img-wrapper">
								<div className="row">
									<div className="col-lg-6">
										<div className="sil1">
											<img
												className="img-fluid so-1"
												src={`${Image}${homeData?.data?.community?.image1}`}
												style={{ transform: `translateY(${trans2}px)` }}
											/>
										</div>

										{/* <img className="img-fluid scroll-1-img" src={approach_2} /> */}
									</div>
									<div className="col-lg-6">
										<div className="sil2">
											<img
												className="img-fluid so-2"
												src={`${Image}${homeData?.data?.community?.image2}`}
												style={{ transform: `translateY(${trans}px)` }}
											/>
										</div>

										{/* <img className="img-fluid scroll-2-img" src={approach_1} /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Community;
